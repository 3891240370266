import "../styles/index.scss";
import "../styles/gutenberg.scss";

import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import React, { useMemo } from "react";
import Helmet from "react-helmet";

import IArticleThumbnail from "../components/ArticleThumbnail/ArticleThumbnail.type";
import Layout from "../containers/Layout/Layout";
import ArticleThumbnails from "../sections/ArticleThumbnails/ArticleThumbnails";
import HeroWithImage from "../sections/HeroWithImage/HeroWithImage";
import PostContent from "../sections/PostContent/PostContent";
import SubscribeCTA from "../sections/SubscribeCTA/SubscribeCTA";
import { stripPTags } from "../utils";

const post = ({ pageContext, data }: any) => {
    const [postContent, postIntro] = useMemo(
        () => data.wpPost.content.split("<!--more-->").reverse(),
        []
    );

    const description = postIntro ? postIntro : data.wpPost.excerpt;
    const mainContent = postIntro ? postContent : null;
    const content = data.wpPost.excerpt ? data.wpPost.excerpt : postIntro;

    return (
        <Layout {...pageContext}>
            <Seo post={data.wpPost} />
            <Helmet
                title={data.wpPost.title + " | Christina Insights"}
                meta={[
                    {
                        name: "author",
                        content: data.wpPost.author.node.name,
                    },
                    {
                        name: "description",
                        content: stripPTags(content),
                    },
                ]}
            />
            <HeroWithImage
                title={data.wpPost.title}
                description={stripPTags(description)}
                rightColumnContent="image"
                image={data.wpPost.featuredImage?.node}
                category={data.wpPost.categories.nodes[0]}
                postAuthor={
                    data.wpPost.categories.nodes[0].slug !== "press" &&
                    data.wpPost.author.node
                }
                postDate={data.wpPost.date}
                blogPost={data.wpPost.blogPost}
                podcastsAndWebinars={data.wpPost.podcastsAndWebinars}
            />
            {mainContent && <PostContent content={mainContent} />}
            <SubscribeCTA />
        </Layout>
    );
};

export default post;

export const query = graphql`
    query ($id: String) {
        wpPost(id: { eq: $id }) {
            excerpt
            content
            title
            date
            author {
                node {
                    name
                    uri
                }
            }
            categories {
                nodes {
                    slug
                    name
                }
            }
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                sizes: "(min-width: 726px) 726px, 100vw"
                                #                                aspectRatio: 1.667
                                transformOptions: { fit: CONTAIN }
                                backgroundColor: "transparent"
                            )
                        }
                        publicURL
                    }
                    altText
                }
            }
            categories {
                nodes {
                    posts {
                        nodes {
                            featuredImage {
                                node {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(aspectRatio: 1.6)
                                        }
                                        publicURL
                                    }
                                }
                            }
                            title
                            excerpt
                            uri
                            author {
                                node {
                                    name
                                    uri
                                }
                            }
                            date
                            categories {
                                nodes {
                                    slug
                                    name
                                }
                            }
                        }
                    }
                }
            }
            blogPost {
                alternativeTitle
                publicationName
                publicationUrl
            }
            podcastsAndWebinars {
                videoId
                linkToFile
                file {
                    mediaItemUrl
                }
                externalPageLink
                buttonText
                buttonAction
            }
            seo {
                opengraphPublisher
                breadcrumbs {
                    text
                    url
                }
                opengraphPublishedTime
                opengraphModifiedTime
                metaRobotsNofollow
                metaKeywords
                metaDesc
                fullHead
                cornerstone
                canonical
                opengraphTitle
                opengraphSiteName
                opengraphUrl
                opengraphType
                readingTime
                twitterDescription
                title
                twitterTitle
                twitterImage {
                    altText
                    srcSet
                    sourceUrl
                }
                schema {
                    articleType
                    pageType
                    raw
                }
                opengraphAuthor
                metaRobotsNoindex
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
            }
        }
    }
`;
